<div *ngIf="!this.doneLoading" class="loading-center">
  <mat-spinner></mat-spinner><br>{{"FLIGHTPICKERPAGE.retrieving_flights" | translate}}
</div>
<div *ngIf="this.doneLoading" style="max-width: 480px; margin: auto;">
  <mat-card class="mat-card-container mat-elevation-z8">
    <mat-card-title class="mat-title">
      <span class="mat-title-span">{{'FLIGHTPICKERPAGE.choose_your_flight' | translate}}</span>
      <a [routerLink]="['/']">
        <mat-icon>arrow_back</mat-icon>
      </a>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="mat-content-container">
      <div class="info-explanation">
        <mat-icon class="icon-explanation">info</mat-icon>
        <span>{{ 'FLIGHTPICKERPAGE.explanation' | translate }}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <mat-icon>flight_takeoff</mat-icon>
        <span>{{this.upcomingFlights.length}} {{ 'FLIGHTPICKERPAGE.flights_found' | translate }}</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-card-container mat-elevation-z8" *ngFor="let flightOption of this.upcomingFlights"
    [ngClass]="flightOption.passengerCounters.freeSpots <= 0 || flightOption.status === 'REA' ?'full-capacity':''">
    <mat-card-subtitle>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50">
          <mat-icon *ngIf="flightOption.passengerCounters.freeSpots > 0 && flightOption.status != 'REA'">event_available</mat-icon>
          <mat-icon *ngIf="flightOption.passengerCounters.freeSpots <= 0 || flightOption.status === 'REA'">event_busy</mat-icon>
          {{getFormattedDate(flightOption.date)}} {{getHour(flightOption.hour)}}
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
          <mat-icon>location_on</mat-icon>
          {{flightOption.location.name}}
        </div>
      </div>
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content class="mat-content-container">
      <div *ngIf="flightOption.passengerCounters.freeSpots > 0 && flightOption.status != 'REA'" >
        <div fxLayout="row" fxLayoutAlign="center center">
          <h2>{{flightOption.passengerCounters.freeSpots}} {{ 'FLIGHTPICKERPAGE.available_places' | translate }}</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" >
          <p>{{ ('FLIGHTPICKERPAGE.request_explanation' | translate)
            .replace('{0}', getFormattedDate(flightOption.date))
            .replace('{1}', getHour(flightOption.hour))
            .replace('{2}', flightOption.location.name) }}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button class="btn"
            (click)="openConfirmationPopup($event, flightOption)">{{ 'FLIGHTPICKERPAGE.request' | translate }}</button>
        </div>
      </div>
      <div *ngIf="flightOption.passengerCounters.freeSpots <= 0 || flightOption.status === 'REA'">
        <div fxLayout="row" fxLayoutAlign="center center">
          <h2>{{"FLIGHTPICKERPAGE.flight_full" | translate}}</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <p>{{ 'FLIGHTPICKERPAGE.unfortunately_this_flight_is_fully_booked' | translate }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ICalendar } from 'datebook';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/_services/url.service';

@Component({
	selector: 'app-flight-card',
	templateUrl: './flight-card.component.html',
	styleUrls: ['./flight-card.component.css'],
})
export class FlightCardComponent implements OnInit {
	@Input() booking: any = null;
	@Output() flightDecision = new EventEmitter<any>();

	myTenantId: string = '';
	myBookingId: string = '';

	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private urlService: UrlService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = myflight.booking.id;
			}
		});
	}

	ngOnChanges() {
		if (this.booking) moment.locale(this.booking.contact_language);
	}

	getDay() {
		if (this.booking.flight.date) {
			return moment(this.booking.flight.date).format('dddd D MMM');
		}
		return;
	}

	getHour() {
		if (this.booking.flight.hour) {
			return moment(this.booking.flight.hour).format('HH:mm');
		}
		return;
	}

	// For rejecting the flight
	openConfirmationPopup(e: any) {
		e.stopPropagation();
		this.translate.get(['FLIGHTCARD.confirmation_title', 'FLIGHTCARD.confirmation_explanation']).subscribe((trans) => {
			var confirmationData = {
				title: trans['FLIGHTCARD.confirmation_title'],
				body: trans['FLIGHTCARD.confirmation_explanation'],
			};
			var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				height: '330px',
				width: '100%',
				data: confirmationData,
			});
			const sub = dialogRef.componentInstance.onYes.subscribe(() => this.rejectFlight(e));
			dialogRef.afterClosed().subscribe(() => {
				sub.unsubscribe();
			});
		});
	}

	acceptFlight() {
		this.urlService.confirmBookingToMyFlight(this.myBookingId, this.myTenantId).subscribe();
	}

	rejectFlight(e: any) {
		this.urlService.rejectBookingToMyFlight(this.myBookingId, this.myTenantId).subscribe();
	}

	downloadCalendar() {
		const icalendar = new ICalendar({
			title: 'Hot Air Balloon Flight',
			location: this.booking.flight.location.name,
			start: moment(this.booking.flight.hour).toDate(),
			end: moment(this.booking.flight.hour).add(1, 'hours').toDate(),
		});

		icalendar.download();
	}
}

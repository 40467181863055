<mat-card class="mat-card-container mat-elevation-z8">
    <mat-card-subtitle>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50">
                <mat-icon>event_available</mat-icon>
                {{getDay()}} {{getHour()}}
            </div>
            <div fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
                <mat-icon>location_on</mat-icon>
                {{booking.flight.location.name}}
            </div>
        </div>
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-card-content class="mat-content-container">
        <div *ngIf="booking.status == 'ADDE'">
            <div fxLayout="row" fxLayoutAlign="center center">
                <h2>{{ 'FLIGHTCARD.request_submitted' | translate }}</h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <p>{{ 'FLIGHTCARD.added_explanation' | translate }}</p>
            </div>
        </div>
        <div *ngIf="booking.status == 'CONF'">
            <div fxLayout="row" fxLayoutAlign="center center">
                <h2>{{ 'FLIGHTCARD.request_confirmed' | translate }}</h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <p>{{ ('FLIGHTCARD.confirmed_explanation' | translate)
                    .replace('{0}', getDay())
                    .replace('{1}', getHour()) }}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button (click)="downloadCalendar()" class="btn">{{ 'FLIGHTCARD.add_to_my_calendar' | translate }}</button>
            </div>
        </div>
        <div *ngIf="booking.status == 'INVI'">
            <div fxLayout="row" fxLayoutAlign="center center">
                <h2>{{ 'FLIGHTCARD.request_invited' | translate }}</h2>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
                <p>{{ ('FLIGHTCARD.invited_explanation' | translate)
                    .replace('{0}', getDay())
                    .replace('{1}', getHour()) }}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <button mat-raised-button (click)="openConfirmationPopup($event)" class="btn" >{{ 'FLIGHTCARD.decline' | translate }}</button>
                <button mat-raised-button (click)="acceptFlight()" class="btn">{{ 'FLIGHTCARD.accept' | translate }}</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
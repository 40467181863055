<div *ngIf="error || isCanceled || isComplete">
	<mat-card class="mat-card-container mat-elevation-z8">
		<mat-card-title class="mat-title mat-title-end">
			<span class="mat-title-span">{{ 'MAINPAGE.error' | translate }}</span>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<div class="info-explanation" *ngIf="error">
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.wrong_url' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.wrong_url_fr' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.wrong_url_en' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.wrong_url_de' | translate }}</span>
				</div>
			</div>
			<button *ngIf="error" mat-raised-button routerLink="/login">{{ 'MAINPAGE.to_login' | translate }}</button>
			<div class="info-explanation" *ngIf="isCanceled">
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.canceled_booking' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.canceled_booking_fr' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.canceled_booking_en' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.canceled_booking_de' | translate }}</span>
				</div>
			</div>
			<div class="info-explanation" *ngIf="isComplete">
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.complete_flight' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.complete_flight_fr' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.complete_flight_en' | translate }}</span>
				</div>
				<div>
					<mat-icon class="icon-explanation">info</mat-icon>
					<span>{{ 'MAINPAGE.complete_flight_de' | translate }}</span>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>

<div *ngIf="!booking && !error && !isCanceled && !isComplete" class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'MAINPAGE.loading' | translate }}
</div>
<app-navbar
	[logoUrl]="tenantInfo.logo"
	[myflight]="myflight"
	*ngIf="tenantInfo && tenantInfo.name && !error && !isCanceled && !isComplete"
>
	{{ tenantInfo?.name | capitalize }}
</app-navbar>

<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" *ngIf="myflight && !error && !isCanceled && !isComplete">
	<div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
		<!-- TODO convert to backend setting for flightPickerDisabled -->
		<app-flight-picker-card
			*ngIf="booking.status === 'OPEN' && !flightPickerDisabled"
			[booking]="booking"
		>
		</app-flight-picker-card>
		<div *ngIf="flightPickerDisabled" style="margin-bottom: 10px"></div>
		<app-flight-card
			*ngIf="booking.status == 'ADDE' || booking.status == 'INVI' || booking.status == 'CONF'"
			[booking]="booking"
		>
		</app-flight-card>
		<app-location-card
			*ngIf="validLocation(booking?.flight?.location) && booking.status === 'CONF'"
			[flight]="booking?.flight"
		>
		</app-location-card>
		<app-chat-card
			*ngIf="myflight?.messagesCollection"
			[messagesCollection]="myflight?.messagesCollection"
			fxHide.xs
			fxHide.sm
		></app-chat-card>
	</div>
	<div
		fxFlex="50%"
		fxFlex.sm="100%"
		fxFlex.xs="100%"
		class="second-column"
		ngStyle.sm="margin-bottom: 10%"
		ngStyle.xs="margin-bottom: 15%"
	>
		<app-booking-card [operatorSettingsFlights]="tenantInfo.operatorSettings.myflight.flights" [booking]="booking">
		</app-booking-card>
		<app-passenger-card [passengers]="booking?.passengers" [flight]="booking?.flight"> </app-passenger-card>
	</div>

	<app-bottom-navbar></app-bottom-navbar>
</div>
